<template>
	<div
		:class="myClasses"
		class="input input--sae"
		:style="!showFullScreenButton || noMaxWidth ? 'max-width: none' : ''"
	>
		<!-- wysiwyg should be full width in fullScreenMode -->
		<label v-if="$slots.default" :for="id" class="input__label input__label--sae">
			<span class="input__label-content input__label-content--sae">
				<slot />
			</span>
		</label>
		<client-only v-if="wysiwyg">
			<WYSIWYG
				:id="id"
				ref="wysiwygInput"
				v-model="internalValue"
				:name="name"
				:show-full-screen-button="showFullScreenButton"
				:max-length="maxLength"
				:styling="styling"
				:is-loading-external-content="isLoadingExternalContent"
				@blur="blur"
				@click="$emit('click')"
				@focus="focus"
			>
				<slot />
			</WYSIWYG>
		</client-only>
		<textarea
			v-else
			:id="id"
			ref="input"
			:value="internalValue"
			:name="name"
			:maxlength="(maxLength !== 0 && maxLength) || undefined"
			:required="required"
			class="input__field input__field--sae cursor-default"
			:class="[getHeight, { 'mb-1': maxLength && !wysiwyg }, { 'resize-none': disableResize }]"
			@blur="blur"
			@click="$emit('click')"
			@change="onChange($event)"
			@input="onChange($event)"
			@focus="focus"
		/>
		<!-- do not show maxLength if wysiwyg is used, due to WYSIWYG now displays its own maxLength inside the component -->
		<p v-if="maxLength && !wysiwyg" class="text-color-text text-xs text-right">
			{{ modelValue.length }}/{{ `${maxLength} Zeichen` || '300 Zeichen' }}
		</p>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import WYSIWYG from './HokTextArea/WYSIWYG.vue';

export default defineComponent({
	name: 'HokTextArea',
	components: { WYSIWYG },
	emits: ['click', 'focus', 'blur', 'update:modelValue'],
	data() {
		return {
			focused: false
		};
	},
	computed: {
		myClasses() {
			const classes: string[] = [];
			if (this.focused || this.internalValue?.trim().length) {
				classes.push('input--filled');
			}
			if (this.focused) {
				classes.push('input--active');
			}
			if (this.fullwidth) {
				classes.push('max-w-full');
			}
			return classes;
		},
		internalValue: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit('update:modelValue', value);
			}
		},
		getHeight(): string {
			switch (this.boxHeight) {
				case 'small':
					return 'h-32';
				case 'medium':
					return 'h-40';
				case 'big':
				default:
					return 'h-64';
			}
		},
		wysiwygInput() {
			return this.$refs.wysiwygInput as any;
		}
	},
	created() {
		if (this.id === 'hoktextarea') {
			console.warn('HokTextArea without explicit id', this);
		}
	},
	methods: {
		hasValue(object: any): object is { value: string } {
			return typeof object === 'object' && 'value' in object;
		},
		setContent(text: string): string {
			if (this.wysiwyg) {
				return this.wysiwygInput.setContent(text);
			}
			this.internalValue = text;
			return this.internalValue;
		},
		onChange(event: Event): void {
			if (this.hasValue(event.target)) {
				this.internalValue = event.target.value;
			}
		},
		focus() {
			this.focused = true;
			this.$emit('focus');
		},
		blur() {
			this.focused = false;
			this.$emit('blur');
		}
	},
	props: {
		showFullScreenButton: { type: Boolean, default: true },
		noMaxWidth: { type: Boolean, default: true },
		name: { type: String, default: '' },
		modelValue: { type: String, default: '' },
		id: { type: String, default: 'hoktextarea', required: true },
		maxLength: { type: Number, default: 0 },
		styling: { type: String, default: 'b2c' },
		boxHeight: {
			type: String,
			default: 'big',
			validator: (value: string) => ['small', 'medium', 'big'].includes(value)
		},
		fullwidth: {
			type: [String, Boolean],
			default: false,
			validator: (value: string | boolean) => [false, 'mobile', 'always'].includes(value)
		},
		required: { type: Boolean, default: false },
		wysiwyg: { type: Boolean, default: false },
		disableResize: { type: Boolean, default: false },
		isLoadingExternalContent: { type: Boolean, default: false, required: false }
	}
});
</script>

<style scoped src="../styles/input.scss" />
<style lang="scss" scoped>
.input__field--sae.textarea {
	padding: 0.5rem;
	border: 1px solid $color-grey-light;
}
</style>

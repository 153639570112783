<template>
	<div :id="id" class="relative">
		<HokIcon
			v-if="isLoadingExternalContent"
			name="icon:spinner"
			class="animate-spin absolute top-4 left-4"
		/>
		<EditorContent
			class="editor-content cursor-text input__field input__field--sae overflow-y-auto rounded-b-none"
			:class="{ '!pl-12 !pt-4 !text-color-grey-medium': isLoadingExternalContent }"
			:editor="editor"
		/>
		<div v-if="editor" id="menu">
			<div class="border rounded border-transition" :class="`${borderColor}`">
				<ToolTip
					icon="admin:ul"
					:size="4"
					window-top
					text="Aufzählungszeichen hinzufügen"
					custom-handler
					class="ml-2 my-1 p-1"
					:class="{ 'is-active': editor.isActive('bulletList') }"
					@click="editor.chain().focus().toggleBulletList().run()"
				/>
				<ToolTip
					icon="admin:headline"
					:size="4"
					window-top
					text="Überschrift erzeugen"
					custom-handler
					class="m-1 p-1"
					:class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
					@click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
				/>
				<ToolTip
					v-if="showFullScreenButton && !$isMobile.any"
					icon="admin:fullscreen"
					:size="4"
					window-top
					class="my-1 p-1"
					text="Full-Screen Modus"
					custom-handler
					@click="openFullScreen"
				/>
				<span class="ml-2">|</span>
				<ToolTip
					icon="admin:remove"
					:size="4"
					window-top
					class="ml-2 my-1 p-1"
					text="Gesamten Text Löschen"
					custom-handler
					@click="editor.commands.clearContent(true)"
				/>
				<ToolTip
					icon="admin:undo"
					:size="4"
					window-top
					class="ml-2 my-1 p-1"
					text="Rückgängig machen"
					custom-handler
					:disabled="!editor.can().undo()"
					@click="editor.chain().focus().undo().run()"
				/>
				<ToolTip
					icon="admin:redo"
					:size="4"
					window-top
					class="ml-2 my-1 p-1"
					text="Wiederherstellen"
					custom-handler
					:disabled="!editor.can().redo()"
					@click="editor.chain().focus().redo().run()"
				/>
				<p
					v-if="editor && hasStorage(editor) && maxLength > 0"
					class="text-sm inline float-right m-2 leading-relaxed"
				>
					{{ editor.storage.characterCount.characters() }}/{{ maxLength }}
					<span class="hide_on_very_small_phone">Zeichen</span>
				</p>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { Editor, EditorContent } from '@tiptap/vue-3';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import Heading from '@tiptap/extension-heading';
import CharacterCount from '@tiptap/extension-character-count';
import History from '@tiptap/extension-history';
import { defineComponent, markRaw, defineAsyncComponent } from 'vue';
import ToolTip from '../ToolTip.vue';
// import { nodeInputRule } from '@tiptap/core';
import { beautifyText } from '../../helpers/beautifyText';
import { EventBus } from '../../eventbus';

/* const CustomHeading = Heading.extend({
	addInputRules() {
		return [nodeInputRule(new RegExp(`^([A-Z0-9_][^a-z]{2,})<template>
	<div :id="id" class="relative">
		<HokIcon
			v-if="isLoadingExternalContent"
			name="icon:spinner"
			class="animate-spin absolute top-4 left-4"
		/>
		<editor-content
			class="editor-content cursor-text input__field input__field--sae overflow-y-auto rounded-b-none"
			:class="{ '!pl-12 !pt-4 !text-color-grey-medium': isLoadingExternalContent }"
			:editor="editor"
		/>
		<div v-if="editor" id="menu">
			<div class="border rounded border-transition" :class="`${borderColor}`">
				<ToolTip
					icon="admin:ul"
					:size="4"
					window-top
					text="Aufzählungszeichen hinzufügen"
					custom-handler
					class="ml-2 my-1 p-1"
					:class="{ 'is-active': editor.isActive('bulletList') }"
					@click="editor.chain().focus().toggleBulletList().run()"
				/>
				<ToolTip
					icon="admin:headline"
					:size="4"
					window-top
					text="Überschrift erzeugen"
					custom-handler
					class="m-1 p-1"
					:class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
					@click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
				/>
				<ToolTip
					v-if="showFullScreenButton && !$isMobile.any"
					icon="admin:fullscreen"
					:size="4"
					window-top
					class="my-1 p-1"
					text="Full-Screen Modus"
					custom-handler
					@click="openFullScreen"
				/>
				<span class="ml-2">|</span>
				<ToolTip
					icon="admin:remove"
					:size="4"
					window-top
					class="ml-2 my-1 p-1"
					text="Gesamten Text Löschen"
					custom-handler
					@click="editor.commands.clearContent(true)"
				/>
				<ToolTip
					icon="admin:undo"
					:size="4"
					window-top
					class="ml-2 my-1 p-1"
					text="Rückgängig machen"
					custom-handler
					:disabled="!editor.can().undo()"
					@click="editor.chain().focus().undo().run()"
				/>
				<ToolTip
					icon="admin:redo"
					:size="4"
					window-top
					class="ml-2 my-1 p-1"
					text="Wiederherstellen"
					custom-handler
					:disabled="!editor.can().redo()"
					@click="editor.chain().focus().redo().run()"
				/>
				<p v-if="editor && maxLength > 0" class="text-sm inline float-right m-2 leading-relaxed">
					{{ editor.storage.characterCount.characters() }}/{{ maxLength }}
					<span class="hide_on_very_small_phone">Zeichen</span>
				</p>
			</div>
		</div>
	</div>
</template>
), this.type)];
	}
}); */

export default defineComponent({
	name: 'WYSIWYG',
	components: {
		EditorContent,
		ToolTip
	},
	emits: ['blur', 'focus', 'click', 'done', 'update:modelValue'],
	data() {
		const fullScreenPage = undefined as Promise<any> | undefined;

		const editor = undefined as Editor | undefined;
		return {
			beautifyText,
			editor,
			focused: false,
			fullScreenPage,
			EventBus
		};
	},
	computed: {
		borderColor() {
			if (this.focused) {
				return 'border-color-main';
			}
			if (this.internalValue?.trim().length) {
				return 'border-color-blue-grey';
			}
			return 'border-color-grey-light';
		},
		internalValue: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit('update:modelValue', value);
			}
		}
	},
	mounted() {
		if (!this.$isMobile.any) {
			this.fullScreenPage = markRaw(
				defineAsyncComponent(() => import('./WYSIWYGFullScreenPage.vue')) as any
			);
		}

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		this.editor = new Editor({
			extensions: [
				Document,
				Paragraph,
				Text,
				BulletList,
				// CustomHeading.configure({ levels: [4] }),
				Heading.configure({ levels: [5] }),
				ListItem,
				CharacterCount.configure({ limit: (this.maxLength !== 0 && this.maxLength) || undefined }),
				History
			],
			content: this.textToHTML(this.internalValue),
			parseOptions: { preserveWhitespace: 'full' },
			onUpdate: ({ editor }) => {
				this.internalValue = this.htmlToText(editor.getHTML());
			},
			onBlur: () => {
				this.focused = false;
				this.$emit('blur');
			},
			onFocus: () => {
				this.focused = true;
				this.$emit('focus');
			}
		});
	},
	beforeUnmount() {
		this.editor?.destroy();
		// todo remove eventbus
		this.EventBus.$off('wysiwyg-fullscreen-input');
		this.EventBus.$off('wysiwyg-fullscreen-done');
	},
	methods: {
		hasStorage(object: any): object is { storage: any } {
			return typeof object === 'object' && 'storage' in object;
		},
		setContent(newVal: string) {
			this.editor?.commands.setContent(this.textToHTML(newVal), false, {
				preserveWhitespace: 'full'
			});
			this.internalValue = this.htmlToText(this.editor?.getHTML());
			return this.internalValue;
		},
		decodeHTMLEntities(text) {
			const textArea = document.createElement('textarea');
			textArea.innerHTML = text;
			return textArea.value;
		},
		htmlToText(text): string {
			if (!text) {
				return '';
			}

			// make sure all headings are in capital letters
			const headings = text.match(/<h5>(.+?)<\/h5>/g);
			headings?.forEach(heading => {
				text = text.replace(heading, heading.toUpperCase());
			});

			return this.decodeHTMLEntities(
				text
					// .replace(/&amp;/g, '&')
					// .replace(/&nbsp;/g, ' ')
					.replace(/<ul>/g, '')
					.replace(/<\/ul>/g, '')
					.replace(/<li><p>/g, '* ')
					.replace(/<\/li>/g, '')
					.replace(/<\/p><\/li>/g, '\n')
					.replace(/<p>/g, '')
					.replace(/<\/p>/g, '\n')
					.replace(/<h5>/gi, '')
					.replace(/<\/h5>/gi, '\n')
			);
		},
		textToHTML(text) {
			const array = this.beautifyText(text);
			let html = '';

			array.forEach(arrayLine => {
				if (arrayLine.type === 'paragraph') {
					html += `<p>${arrayLine.values[0].value}</p>`;
				} else if (arrayLine.type === 'headline') {
					html += `<h5>${arrayLine.value}</h5>`;
				} else if (arrayLine.type === 'list') {
					html += '<ul>';
					arrayLine.values.forEach(value => {
						html += `<li>${value[0].value}</li>`;
					});
					html += '</ul>';
				} else if (arrayLine.type === 'emptyLine') {
					html += `<p></p>`;
				}
			});
			return html;
		},
		async openFullScreen() {
			if (!this.fullScreenPage) {
				this.fullScreenPage = markRaw(
					defineAsyncComponent(() => import('./WYSIWYGFullScreenPage.vue')) as any
				);
			}
			try {
				await this.$page.push(
					this.fullScreenPage,
					{ ...this.$props, value: this.internalValue, styling: this.styling },
					{
						// eslint-disable-next-line vue/require-slots-as-functions
						pageTitle: this.$slots?.default?.[0]?.text || '',
						name: 'WYSIWYG FullScreen',
						slots: this.$slots,
						done: () => {
							this.EventBus.$off('wysiwyg-fullscreen-input');
							this.EventBus.$off('wysiwyg-fullscreen-done');
						}
					}
				);
				this.EventBus.$on('wysiwyg-fullscreen-input', value => {
					this.setContent(value);
				});
				this.EventBus.$on('wysiwyg-fullscreen-done', () => {
					this.editor?.commands.setContent(this.textToHTML(this.internalValue), false, {
						preserveWhitespace: 'full'
					});
					this.$page.goBack();
				});
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
			this.$emit('click');
		}
	},
	props: {
		modelValue: { type: String, required: true },
		maxLength: { type: Number, default: 0 },
		id: { type: String, required: true },
		showFullScreenButton: { type: Boolean, default: true },
		styling: { type: String, default: 'b2c' },
		isLoadingExternalContent: { type: Boolean, default: false, required: false }
	}
});
</script>
<style scoped src="../../styles/input.scss" />
<style scoped lang="scss">
.editor-content {
	::v-deep(ul) {
		list-style: disc;
		padding-left: 1rem;
	}
	::v-deep(p) {
		margin: 0;
	}
	::v-deep(.ProseMirror) {
		min-height: 5rem;
		max-height: 50vh;
		@media (min-width: $sm) {
			min-height: 10rem;
			max-height: 65vh;
		}
	}
	::v-deep() {
		user-select: auto;
	}
}

.is-active {
	background-color: rgba(0, 0, 0, 0.1);
}
.border-transition {
	transition: border 300ms ease;
}
</style>
